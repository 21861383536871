<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Reporte de Pagos Detallado</strong>
          </CCardHeader>
          <CCardBody>

          <b-form id="Form" @submit.prevent="Validate">
            <b-row class="justify-content-center">
                <b-col md="6">
                  <b-form-group>
                    <label>Socio: </label>
                    <v-select placeholder="Todos" class="w-100" :filterable="false" label="full_name" v-model="partner" @search="SearchPartner" :options="partners"></v-select>
                    <small v-if="errors.id_partner" class="form-text text-danger" >Seleccione un socio</small>
                  </b-form-group>
                </b-col>

           

                <b-col sm="2" >
                  <b-form-group label="Desde">
                    <b-form-input type="date" v-model="report.from"></b-form-input>
                    <small v-if="errors.from" class="form-text text-danger" >Seleccione un año</small>
                  </b-form-group>
                </b-col>

                <b-col sm="2" >
                  <b-form-group label="Hasta">
                    <b-form-input type="date" v-model="report.to" ></b-form-input>
                    <small v-if="errors.to" class="form-text text-danger" >Seleccione un año</small>
                  </b-form-group>
                </b-col>


                

                <b-col sm="12" md="1">
                  <b-form-group label=".">
                    <b-button class="form-control" type="submit" variant="primary"><i class="fas fa-search"></i></b-button>
                  </b-form-group>
                </b-col>

                <b-col sm="12" md="1">
                  <b-form-group label=".">
                    <b-button class="form-control" type="button" variant="success" @click="ExportExcel"><i class="fas fa-file-excel"></i></b-button>
                  </b-form-group>
                </b-col>

                

            </b-row>
          </b-form>


              <div class="table-responsive mt-3">
              <table class="table table-hover table-bordered">
                <thead>
                  <tr>
                    <th class="text-center" colspan="19">REPORTE DE PAGOS DE PRESTAMOS DETALLADO  ( {{report.from}} -  {{report.to}}) </th>
                  </tr>
                  <tr>
                    <th width="3%" class="text-center">#</th>
                    <th width="8%" class="text-center">N° Pago</th>
                    <th width="8%" class="text-center">N° Prestamo</th>
                    <th width="7%" class="text-center">Couta</th>
                    <th width="10%" class="text-center">F. Venc.</th>
                    <th width="8%" class="text-center">C. Final</th>
                    <th width="10%" class="text-center">F. Pago</th>
                    <th width="10%" class="text-center">Moros.</th>
                    <th width="10%" class="text-center">Desc.</th>
                    <th width="10%" class="text-center">Total</th>
                  </tr>
                </thead>
                <tbody >
                  <tr v-for="(item, it) in data_table" :key="it">
                    <td class="text-center">{{ it + 1 }}</td>
                    <td class="text-center"> {{ item.code  }}</td>
                    <td class="text-center"> {{ item.number  }}</td>
                    <td class="text-center"> {{ item.installment_number  }}</td>
                    <td class="text-center"> {{ item.expiration_date  }}</td>
                    <td class="text-right"> {{ item.fixed_fee  }}</td>
                    <td class="text-center"> {{  item.payment_date }}</td>
                    <td class="text-right"> {{  item.delinquent_amount }}</td>
                    <td class="text-right"> {{  item.discount }}</td>
                    <td class="text-right"> {{  item.total_fee }}</td>
                  </tr>
                </tbody>
               
              </table>
            </div>

  
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <LoadingComponent :is-visible="isLoading"/>
  </div>
</template>

<script>
import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import "vue-select/src/scss/vue-select.scss";

const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
import { mapState } from "vuex";
import CodeToName from "@/assets/js/CodeToName";
var moment = require("moment");
import LoadingComponent from './../pages/Loading'

export default {
  name: "UsuarioList",
  components:{
    vSelect,
    LoadingComponent,
  },
  data() {
    return {
      isLoading: false,
      module: 'ReportLoanPaymentDetail',
      role:1,
      perPage: 15,
      currentPage: 1,
      rows: 0,
      data_table: [],


      report:{
        id_partner:'',
        from:moment(new Date()).local().format("YYYY-MM-DD"),
        to:moment(new Date()).local().format("YYYY-MM-DD"),
        number:'',
      },
      partners : [],
      partner: null,
     
  
      errors:{
        id_partner:false,
        to:false,
        from:false,
      }
    };
  },
  mounted() {
   
  },
  methods: {
    SearchPartner,
    Validate,
    Report,
    ExportExcel,
  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
    id_establishment: function () {
      let id_establishment = window.localStorage.getItem("id_establishment");
      id_establishment = JSON.parse(je.decrypt(id_establishment));
      return id_establishment;
    },
  },
};

function SearchPartner(search, loading) {
  
   let me = this;
    let url = this.url_base + "search-partner/" + search;
    if (search !== "") {
      loading(true);
      axios({
        method: "GET",
        url: url,
      }).then(function (response) {
            me.partners = response.data;
            loading(false);
      })
    }
}


function ExportExcel() {  
  // this.errors.id_partner = this.partner == null ? true : false;
  this.errors.from = this.report.from.length == 0 ? true : false;
  this.errors.to = this.report.to.length == 0 ? true : false;

//  if (this.errors.id_partner == true) { return false; }
 if (this.errors.from == true) { return false; }
 if (this.errors.to == true) { return false; }
  let id_partner = this.partner == null ? 'all':this.partner.id;
  let me = this;
  let url = me.url_base + "report-loan-payment-detail-excel/"+id_partner+"/"+me.report.from+"/"+me.report.to;
  window.open(url,'_blank');
}

function Validate() {
  
  // this.errors.id_partner = this.partner == null ? true : false;
  this.errors.from = this.report.from.length == 0 ? true : false;
  this.errors.to = this.report.to.length == 0 ? true : false;

  // if (this.errors.id_partner == true) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.from == true) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.to == true) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }

  this.Report();
}


function Report() {
  let me = this;
  me.report.id_partner = me.partner == null ? 'all': me.partner.id;
  let data = me.report;
  let url = this.url_base + "report/loan-payment-detail";
  me.isLoading = true;
  axios({
    method: "POST",
    url: url,
    data: data,
    headers: { token: this.token, module: this.module, role: 1,},
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.data_table = response.data.result;
      } else {
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
      me.isLoading = false;
    })
     .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = false;
    });
}





</script>
